import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import Hero from "../../components/hero/hero";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import Button from "../../components/custom-widgets/button";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CommercialFinancing = () => {
  const title = "Commercial Credit Solutions";
  const description =
    "If you're in the market for commercial lines of credit or business loans of any kind, WaFd Bank can help. Learn more here.";
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: { eq: "hero/commercial-banking/commercial-financing/hero-commercial-financing-01-2500.jpg" }
      ) {
        ...heroImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/commercial-banking/commercial-financing/hero-commercial-financing-01-1920.jpg" }
      ) {
        ...heroImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/commercial-banking/commercial-financing/hero-commercial-financing-01-1200.jpg" }
      ) {
        ...heroImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/commercial-banking/commercial-financing/hero-commercial-financing-01-992.jpg" }
      ) {
        ...heroImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/commercial-banking/commercial-financing/hero-commercial-financing-01-768.jpg" }
      ) {
        ...heroImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/commercial-banking/commercial-financing/hero-commercial-financing-01-600.jpg" }
      ) {
        ...heroImageFragmentXS
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/commercial-financing"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };
  const heroData = {
    id: "commercial-financing-hero",
    ...getHeroImgVariables(imgData, ["xs", "sm", "md", "lg", "xl", "xxl"]),
    altText: "Small Business owner standing infront of a store",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "We take your business financing personally."
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: "active",
      title: "Credit Solutions"
    }
  ];

  const findaBankeBtnData = {
    type: "link",
    id: "find-a-banker",
    containerClass: "mb-4",
    class: "btn-primary",
    text: "Find a Business Banker Near You",
    showIcon: false,
    url: "/commercial-banking/commercial-banker-near-me"
  };

  const GreenCheckmark = () => (
    <span className="fa-li">
      <FontAwesomeIcon className="mr-2 text-success" icon={["fas", "check"]} />
    </span>
  );

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Hero {...heroData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Credit Solutions</h1>
        <p>
          For more than 100 years, we've taken a personal approach to protecting your long-term interests. Consider our
          business bankers your own trusted advisors, dedicated to working with you to develop a straight-forward
          financing strategy that's right for you. After all, we understand that our success depends on yours.
        </p>
        <h2>Business Lines of Credit</h2>
        <p>
          If you need to borrow funds to support cyclical or seasonal cash flow, Business Lines of Credit are often the
          right solution.
        </p>
        <ul className="fa-ul">
          <li>
            <GreenCheckmark />
            Business lines of credit require monthly payments only when you are using the line.
          </li>
          <li>
            <GreenCheckmark />
            Combine a line of credit with our Business Sweep Accounts. We can automatically transfer funds daily between
            an operating account and related line of credit to efficiently manage cash resources, providing maximum
            flexibility with higher earnings potential.
          </li>
          <li>
            <GreenCheckmark />
            Sole proprietor? Ask us about using a home equity line of credit to fund a small business.
          </li>
        </ul>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <h2>Business Term Loans</h2>
              <p>
                If your business needs to purchase equipment, make capital improvements, or finance future expansion,
                Business Term Loans are the ideal solution.
              </p>
              <ul className="fa-ul">
                <li>
                  <GreenCheckmark />
                  Business Term Loans typically have terms of 1-7 years.
                </li>
                <li>
                  <GreenCheckmark />
                  We tailor the loan to fit your cash flow.
                </li>
              </ul>
            </div>
            <div className="col-md-5 text-center">
              <StaticImage src="../../images/remodeling.png" quality="100" alt="house" placeholder="blurred" />
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2>Owner-Occupied Real Estate Financing</h2>
            <p>
              Commercial real estate loans help business owners finance the purchase of new or existing real estate
              holdings. And whether you need to build from scratch or remodel, we've got the construction financing
              experience you're looking for.
            </p>
            <ul className="fa-ul">
              <li>
                <GreenCheckmark />
                For owner-occupied commercial properties.
              </li>
              <li>
                <GreenCheckmark />
                Available for purchase, refinance, construction and remodel.
              </li>
              <li>
                <GreenCheckmark />
                Fully amortizing and balloon payment options are available.
              </li>
              <li>
                <GreenCheckmark />
                Maximum loan-to-value of 75%
              </li>
            </ul>
          </div>
          <div className="col-md-5 text-center">
            <StaticImage src="../../images/storefronts-white.jpg" quality="100" alt="Store" placeholder="blurred" />
          </div>
        </div>
      </section>
      <hr className="d-md-none"></hr>
      <section className="container">
        <h2>Acquisition Financing</h2>
        <p>
          Business acquisition financing will be customized based on the needs and financial characteristics of your
          company.
        </p>
        <ul className="fa-ul">
          <li>
            <GreenCheckmark />
            No two acquisition deals are ever the same. Rely on the experience of our Business Bankers.
          </li>
          <li>
            <GreenCheckmark />
            We'll work with you to determine the best balance of equity and debt for financing your acquisition needs.
          </li>
          <li>
            <GreenCheckmark />
            You'll receive top-level attention from our leadership team. We're accessible, local and hands-on.
          </li>
        </ul>
      </section>
      <section className="bg-light">
        <div className="container">
          <h2>We understand your needs.</h2>
          <div className="row align-items-center">
            <div className="col-md-6 col-lg-7">
              <p>
                Our local business bankers have the knowledge and experience to help you make practical financial
                decisions. From checking accounts to lines of credit, wire transfers to online banking, we'll work with
                you to recommend innovative products and services to fit your unique business.
              </p>
            </div>
            <div className="col-md-6 col-lg-5 text-center">
              <Button {...findaBankeBtnData} />
            </div>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};
export default CommercialFinancing;
